.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.parent {
 
  margin: 1rem;
  padding: 2rem 2rem;
  text-align: center;
}
.child {
  display: inline-block;

 
  vertical-align: middle;
}

h1 {
  font-size: 3rem;
  margin-bottom: 30px;
}

.transcript-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.transcript-textarea {
  height: 200px;
  width: 600px;
  font-size: 1.2rem;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  outline: none;
}
.listen {
  height: 65px;
  width: 130px;
  
  font-size: 1.2rem;
  margin-left: 20px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  /* background-color: #6c63ff; */
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 139, 0.24) !important;
  
  
}
.stop {
  height: 65px;
  width: 65px;
  
  font-size: 1.2rem;
  margin-left: 20px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  /* background-color: #6c63ff; */
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;
  background: url('/public/images/stop.png') no-repeat !important;
  background-size: cover;
}

.record-button {
  height: 65px;
  width: 65px;
  
  font-size: 1.2rem;
  margin-left: 20px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  /* background-color: #6c63ff; */
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;
  background: url('/public/images/mute.png') no-repeat;
  background-size: cover;
}

.record-button:hover {
  /* background-color: #3f3d9f; */
  background: url('/public/images/mutehover.png') no-repeat;
}

.record-button.recording {
  background-color: #f94144;
  background: url('/public/images/record.png') no-repeat;
}

.record-button.recording:hover {
  background-color: #bf2126;
  background: url('/public/images/record.png') no-repeat;
}

.record-button:active {
  transform: scale(0.95);
}
